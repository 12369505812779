.wallet-header{
    background: linear-gradient(138.47deg, #FF6161 -2.56%, #FD8F8F 76.52%);
    height: 2.62rem;
}
.wallet-header-cxt{
    margin-top:0.5rem;
    height:1rem;
}
.wallet-line{
    height: 35px;
    width:2px;
    background-color: #FFFFFF;
    border-radius: 1px;
}
.font-size26{
    font-size:0.26rem;
}
.wallet-item{
    letter-spacing: -0.24px;
}
.wallet-item>div:first-child{
    line-height:0.36rem;
}
.wallet-item>div:last-child{
    line-height:0.28rem;
}
.wallet-menu{
   width: 3.44rem;
   margin: -1rem auto;
   background: #FFFFFF;
   border-radius: 12px;
   padding:0.3rem 0rem;
}
.wallet-menu-item{
    width:3.08rem;
    height:0.4rem;
}
.wallet-menu-item i{
    transform: rotate(180deg);
    color: #C2C2C2;
}
.wallet-menu-item span{
    display: inline-block;
    margin-left:4px;
}
