.review-container {
    border-radius: 20px;
    padding: 20px;
    /* width: 200px; */
    display: flex;
    flex-direction: column;
    background-color: white;
    min-height: 362px;
}
.review-icon-container {
    margin-top: -25px;
}

.review-head {
    margin-top: 10px;
}

.review-detail {
    color: #ACACAC;
    font-size: 11px;
}
.review-consume>div{
    margin-left: 20px;
    margin-right: 20px;
}

.review-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.review-list-item {
    margin:5px;
    border-radius:4px;
    padding:4px;
    color: white;
}

.review-list-item.inactive {
    background-color: #C0CBD7;
    ;
}

.review-list-item.active {
    background-color: pink;
}

.review-submit {
    width: 260px;
    height: 44px;
    left: 31px;
    top: 343px;
    background: linear-gradient(90.75deg, #599BFF 5.49%, #8FCCFF 94.73%);
    border-radius: 40px;
    color:white;
}


.review-submit.inactive {
    width: 260px;
    height: 44px;
    left: 31px;
    top: 343px;
    background: #C0CBD7;
    border-radius: 40px;
    color:white;
}


.review-tab-container {
    margin:8px;
    margin-top: 15px;
}
.review-tab-left {
    color: #c0cdd8;
    background-color: white;
    padding: 4px 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #c0cdd8;
}
.review-tab-right {
    color:#c0cdd8;
    background-color: white;
    padding:4px 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #c0cdd8;
}

.review-tab-active {
    background-color:#FF7A7A;
    ;
    color:white;
}

.review-icon {
    width: 17px;
    height: 14px;
}