.user-rates{
    width:3.35rem;
    margin: 0 auto;
    padding-top: 0.7rem;
}
.u-rate-page{
    padding-bottom: 0.32rem;
    padding-top: 30px;
}

.search-input {
    /* position: absolute; */
    width: 313px;
    height: 32px;
    left: 0px;
    top: 0px;

    background: #F5F7F8;
    border-radius: 8px;
}

.search-input-control {
    border: none;
    background: transparent;
    margin-top: 5px;
    width: 80%;
}
.search-input >img {
    height: 15px;
    width: 15px;
    position: absolute;
    right:28px;
    top:20px;
}