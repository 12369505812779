.one2one{
    width:100%;
    height:100vh;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.85);
}
.main-video{
    position: absolute;
    left:0;
    top:0;
    z-index:22;
    width:100%;
    height:100%;
}
.calling-video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 22;
    width: 100%;
    height: 100%;
    background-color: #180518cf;
}
.other-video{
    position: absolute;
    right:0.19rem;
    top:0.7rem;
    width: 0.9rem;
    height: 1.6rem;
    z-index: 333;
}
.main-home{
    min-width: 0.99rem;
    max-width: 1.5rem;
    height: 0.34rem;
    border-radius:0.54rem;
    position: absolute;
    top:0.44rem;
    left: 0.2rem;
    z-index:33;
    background: rgba(0, 0, 0, 0.4);
}
.calling-home {
    padding-top: 1rem;
}
.main-cost{
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    height: 0.26rem;
    border-radius: 0.54rem;
    z-index:33;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0.68rem;
    left: 0.2rem;
    line-height: 0.26rem;
}
.calling-name{
    margin-top: 0.1rem;
}
.calling-cost {
    margin-top: 0.1rem;
    z-index: 30;
    background-color: #9999;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.1rem;
}
.main-home-avatar{
    width: 0.28rem;height: 0.28rem;border-radius:0.28rem;
    overflow: hidden;z-index: 333;
    margin-left:3px;
    margin-right: 3px;
}
.calling-home-avatar{
    width: 0.56rem;height: 0.56rem;
    border-radius:0.56rem;
    overflow: hidden;z-index: 333;
    margin-left:6px;
}
.main-home-avatar>img{
    display: inline-block;
    width: 100%;height: 100%;
    object-fit: cover;
    
}
.calling-home-avatar>img{
    display: inline-block;
    width: 100%;height: 100%;
    object-fit: cover;
    
}
.one2one-user-info{
    margin-right:0.17rem;
    line-height: 0.17rem;
}
.one2one-user-info .one2one-nickname {
    white-space: nowrap;
    max-width: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
}
.one2one-user-btn{
    width: 0.44rem;
    height: 0.15rem;
    background: linear-gradient(93.9deg, #FF8C43 13.4%, #FFCE59 96.38%);
    border-radius: 0.29rem;
    line-height:0.15rem;
}
.one2one-menuItem{
    width: 0.34rem;
    height: 0.34rem;
    border-radius:100%;
    background: rgba(0, 0, 0, 0.4);
}
.main-menu{
    position: absolute;
    top:0.44rem;
    right: 0.2rem;
    z-index: 33;
}
.one2one-menuItem:first-child{
    margin-right:0.21rem;
}
.one2one-menuItem:last-child{
    line-height:0.34rem;
}
.one2one-menuItem>i{
    font-size:0.18rem;
    color: #fff;
}
.main-menu-dim{
    width: 1.11rem;
    height: 0.43rem;
    border-radius: 0.2rem 0px 0px 0.2rem;
    z-index:33;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 3.18rem;
    right: 0rem;
}
.main-menu-dim>i{
    font-size:0.24rem;
    color: #fff;
    
}

.calling-menu-dim{
    width: 1.11rem;
    height: 0.43rem;
    border-radius: 0.2rem 0px 0px 0.2rem;
    z-index:33;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    bottom: 1.8rem;
    right: 0rem;
}
.calling-menu-dim>i{
    font-size:0.24rem;
    color: #fff;
    
}

.main-menu-bar{
    z-index:33;
    bottom:1.61rem;
    left: 0.3rem;
    position: absolute;
}
.main-menu-barItem{
    width:0.39rem;height:0.39rem;
    border-radius: 0.39rem;
    background: rgba(0, 0, 0, 0.4);
    overflow:hidden;
}
.main-menu-barItem i,.pause-btn i{
    font-size:0.24rem;
    color: #fff;
}



.pause-btn i{
    font-size: 0.32rem;
}

.accept-btn {
    /* background-color: rgb(62, 247, 62); */
    border-radius: 100%;
    width:0.64rem;
    height:0.64rem;
    margin:0.2rem;
}

.accept-btn>i{
    transform: rotate(45deg);
    color: white;
    font-size: 0.32rem;
}
.main-menu-bar>div{
    margin-top: 0.12rem;
}
.main-menu-pause{
    width: 100%;
    position: absolute;
    z-index:33;
    bottom:1.8rem;
    left:0px;
}

.calling-status{
    margin-top:2rem;
    z-index: 30;
}

.menu-pause-state{
    padding-left: 0.16rem;
    /* padding-right: 0.16rem; */
    height: 31px;
    border-radius: 0.54rem;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    line-height:0.26rem;
}
.pause-btn{
    width:0.64rem;
    height:0.64rem;
    border-radius:100%;
    margin: 0.2rem;
}

.one2one-gift{
    z-index:33;
    bottom:1.81rem;
    right: 0.33rem;
    position: absolute;
}

.one2one-intimacy{
    z-index:33;
    bottom:2.41rem;
    right: 0.33rem;
    position: absolute;
}


.one2oneItem{
    width:0.39rem;height:0.39rem;
    border-radius: 0.39rem;
    background: rgba(0, 0, 0, 0.4);
    overflow:hidden;
}
.one2oneItem i{
    font-size: 0.24rem;
    color: #D50303;
}
.one2oneItem>span{
    visibility: hidden;
}

.uptext {
    z-index: 33;
}

.videos {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
}
.videos-1 {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
}

.vid-remote {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
}

.vid-local {
    position: fixed;
    z-index: 1000;
    width:100px;
    height: 160px;
    top:100px;
    right:0px;
}

.chat-input-container {
    position: absolute;
    z-index: 10000;
    bottom: 0.1rem;
    display: flex;
    flex-direction: row;
    /* height: 0.1rem; */
    /* width: 100px; */
    width: 100%;
}
.history {
    position: absolute;
    background: #ffffff61;
    /* opacity: 0.2; */
    left: 1rem;
    right: 1rem;
    bottom: 2rem;
    height: 2rem;
    overflow: hidden;
}
.history-close {
    position: absolute;
    right: 0px;
}
.calling-hangup {
    width: 54px;
}
.calling-gift {
    width: 39px;
}

.tocharge {
    width: 71px;
    height: 31px;
    left: 0px;
    top: 0px;

    /* Linear */
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 20px;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
}