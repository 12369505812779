.auth-page {
    background-color:#F5F5F5;
    height: 100vh;
}

.auth-head {
    /* background-image: url('../../assets/images/auth-head.png'); */
    width: 100%;
    margin-top: 50px;
    z-index: 10;
}

.auth-list{
    margin-top: -10px;
    /* border-radius: 20px; */
    background-color: white;
    padding: 25px 43px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.auth-row {
    padding: 8px;
    width: 100%;
}

.auth-status {
    width: 20px;
}

.auth-status img {
    width: 15px;
    height: 15px;
}

.auth-title2 {
    color:#999;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.24px;

    margin-bottom: 35px;
}
.auth-icon {
    margin-right: 10px;
}
.auth-icon img {
    width: 30px;
    height: 30px;
}

.auth-desc-2 {
    color: #999999;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
}