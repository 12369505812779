.navigation{
    background-color: #fff;
    z-index: 999;
    overflow: hidden;
}
.nav-container{
    width:3.35rem;
    margin: 0 auto;
    height:100%;
}
.navigation-back{
    left:0;
}
.nav-title{
    height:100%;
}
.navigation-right {
    width: 0.17rem;
}