.chat-msg-container {
    /* height: 450px; */
    overflow: scroll;
    padding-top: 0.1rem;
}

.chat-msg-line-head-icon {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 24px;
}

.msg-time {
    color:#ACACAC;
}

.msg-gift{
    width:40px;
    height: 40px;
}