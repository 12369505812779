.deposit-dialog {
    width: 318px;
    height: 192px;
    left: 0px;
    top: 0px;

    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px;
}

.deposit-dialog-icon {
    width:75px;
    width:62px;
    margin-bottom: 25px;
    margin-top: 5px;
}

.deposit-dialog-footer {
    margin-top: 18px;
    display: flex;
    padding: 25px 20px;
    justify-content: center;
    align-items: center;
    
}

.deposit-dialog-footer .close {
    width: 72px;
    height: 28px;
    left: 0px;
    top: 0px;

    border: 1px solid #ACACAC;
    border-radius: 100px;
    color: #ACACAC;

}


.deposit-dialog-footer .ok {
    width: 72px;
    height: 28px;
    left: 0px;
    top: 0px;
    
    /* Linear */
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 100px;
    color:white;
    border:none;
    margin:0px 20px;
}