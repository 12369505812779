.home-btn{
    width: 3.35rem;
    height: 0.56rem;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 0.72rem;
    /* line-height:0.56rem; */
    box-sizing: border-box;
    position: absolute;
    width: 284px;
    height: 42px;
    /* left: 0px; */
    /* top: 0px; */
    background: linear-gradient(182deg, #864DfC 30%, #1E047E 100%);
    box-shadow: inset 0px -4px 12px rgb(255 255 255 / 35%);
    border-radius: 77px;
    line-height: 40px;
  }
  .home-page{
    width: 100%;
    height:88vh;
    overflow: hidden;
  }
  .home-img{
    width: 100%;
    height:100vh;
    top: 0;
    left:0;
    z-index:-1;
  }
  .home-img>img{
    display: inline-block;
    width: 100%;
    height:100%;
    background-size: 100%;
    object-fit:cover;
  }
  .home-btn-container{
     bottom:1rem;
     left:0rpx;
  }
  .icon-title{
     top:0.26rem;
     left:0.28rem;
     font-size:0.32rem;
  }
.live-link {
    width:175px;
    height:42px;
    position: absolute;
    right:12px;
    top:57px;
    z-index: 2;
}
.pag-canvas {
    width:100%;
    height: 100%;
}


.first_icon img {
  
  position: absolute;
  width: 119px;
  height: 94.46px;
  left: 204px;
  top: 105px;

}

.first_ok_btn {
  box-sizing: border-box;
  position: absolute;
  width: 139px;
  height: 45px;
  left: 50%;
  top: 65%;
  border: 2px solid #FFFFFF;
  border-radius: 35px;
  cursor: pointer;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0px);
}