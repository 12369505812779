.person-page{
    width: 100%;
    height: 95vh;
    overflow: scroll;
    padding-bottom:1.2rem;
}
.person-header{
    height:3.3rem;
    z-index:1;
}
.custom-indicator{bottom:0.5rem;}
.swiper-point{
    width: 6px;
    height: 4px;
    background: #FFFFFF;
    opacity: 0.5;
    border-radius: 14px;
}

.swiper-point-active{
    width:15px;
    height: 4px;
    background: #FFFFFF;
    border-radius: 14px;
}
.swiper-point-item{
    margin-right:6px;
}

.person-info{
    /* margin-top:-0.35rem;
    background-color: #FFFFFF;
    border-radius: 0.12rem 0.12rem 0px 0px;
    padding-top:0.26rem;
    position: relative;
    z-index: 99;
    padding-bottom:0.5rem; */
    margin-top: -0.15rem;
    background-color: #FFFFFF;
    border-radius: 0.12rem 0.12rem 0px 0px;
    padding-top: 0.15rem;
    position: relative;
    z-index: 99;
    padding-bottom: 0.5rem;
}
.person-info>div{
    width:3.35rem;
}
.person-level{
    /* height:0.18rem;
    padding:0px 0.1rem;
    border-radius: 0.46rem;
    zoom:0.5;
    font-size:0.2rem;
    line-height:0.18rem;
    text-align: center;
    display: inline-block; */
    height: 18px;
    padding: 0px 10px;
    border-radius: 20px;
    /* zoom: 0.5; */
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    display: inline-block;
}
.person-base-signtext{
    line-height: 0.18rem;
    margin-top:0.04rem;
}
.person-base-fans{
    margin-top:0.04rem;
}
.person-base-fans{
    line-height:0.15rem;
}
.person-base-fans>span{
    line-height:0.18rem;
}

.person-info-auth{
    margin-top:0.31rem;
    padding-bottom: 0.28rem;
    border-bottom: 1px solid #EDEDED;
}

.person-auth-item{
    margin-top:0.1rem;
    margin-right: 0.29rem;
}
.person-auth-item>span{
    margin-top:5px;
}
.person-info-circle{
    padding: 0.31rem 0rem;
    border-bottom: 1px solid #EDEDED;
}
.circle-info{
    width: 0.44rem;
    height: 0.16rem;
    background: #FF5C5C;
    border-radius: 0.19rem;
    text-align: center;
    line-height:0.16rem;
    display: inline-block;
    padding: 0.04rem 0.05rem;
    margin-right: 0.11rem;
}
.person-info-circle i{
    transform: rotate(180deg);
    color: #C6C6C6;
}
.person-dynamic-slider>div:last-child{
    transform: rotate(180deg);
    color: #C6C6C6;
}
.commands-header>div:last-child>i{
    transform: rotate(180deg);
    color: #C6C6C6;
    display: inline-block;
    margin-left:0.15rem;
}
.person-info-summery{
    margin-left: 0.16rem;
}
.person-info-dynamic{
    padding:0.28rem 0rem 0.22rem 0rem;
    border-bottom: 1px solid #EDEDED;
}
.dynamic-slider-albums,.sortlist-slider-albums{
    flex: 1;
    overflow-x: scroll;
}
.dynamic-slider-albums::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.sortlist-slider-albums::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.slider-albums-item{
    margin-right:0.1rem;
    position: relative;
}
.sortlist-albums-item{
    margin-right: 0.12rem;
}
.sortlist-slider>div:last-child{
    transform: rotate(180deg);
    color: #C6C6C6;
}
.person-dynamic-slider{
  margin-top:0.14rem;
}

.person-info-sortlist{
    padding:0.28rem 0rem 0.31rem 0rem;
    border-bottom: 1px solid #EDEDED;
}

.sortlist-slider{
    margin-top:0.17rem;
}
.person-info-personals{
    padding: 0.28rem 0rem;
    border-bottom: 1px solid #EDEDED;
}
.person-info-commands{
    padding: 0.28rem 0rem;
}
.person-info-commands>div:last-child{
    margin-top:0.14rem;
}
.personal-tag{
    background: #F5F5F5;
    border-radius: 0.35rem;
    padding: 0.04rem 0.1rem;
    margin-right:0.16rem;
    margin-bottom:0.08rem;
}
.peronal-tag-label{
    color: #BCBCBC
}
.personal-tag-container{
    margin-top:0.14rem
}
.commands-header-span{
    margin-left: 0.2rem;
    color: #999;
}




.person-bar{
    position: fixed;
    left: 0;
    background: #FFFFFF;
    bottom:0px;
    z-index: 1999;
    overflow: hidden;
    border-top: 1px solid #ECECEC;
    padding-bottom: 0.48rem;
}
.person-bar-text{
    color: #2B2B2B;
}
.personBarItem{
    height:0.4rem;
    width:0.6rem;
}
.personBarItem>span{
    text-align: center;
}
.person-bar>div{
    margin-top: 0.22rem;
}
.person-bar>div:first-child{
    margin-left: 0.27rem;
}
.personBarButton{
    width:1.53rem;
    height:0.4rem;
    border-radius: 143px;
    margin-right:0.27rem;
}
.personBarButtonDisabled{
    background: #C9C9C9;
}
.personBarButtonEnabled{
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 32px;
}

.personBarButton>div{
    margin-left:0.2rem;
}
.personBarButtonText{
    margin-left: 0.08rem;
}
.personBarTitle{
    line-height: 0.18rem;
}
.personBarPrice{
    line-height: 0.18rem;
}

.person-head-back{left:0.23rem;top:0.5rem;}
.person-head-menu{right:0.23rem;top:0.45rem;}
.person-head-back,.person-head-menu{padding:4px;}
.personOnlineState{
    width: 0.47rem;
    height: 0.26rem;
    border-radius: 0.23rem;
    left:0.2rem;
    bottom:0.4rem;
    text-align: center;
    line-height: 0.26rem;
}
.OnlineState1{
    /* background: linear-gradient(276.03deg, #6AFFDB 7.57%, #0FE2B0 96.32%); */
    background: linear-gradient(276.03deg, #6AFFDB 7.57%, #0FE2B0 96.32%);
}
.OnlineState2{
    /* background: linear-gradient(276.03deg, #ead47c 7.57%, #f0c723 96.32%); */
    background: linear-gradient(276.03deg, #FFD869 7.57%, #FFB132 96.32%);
}
.OnlineState0{
    /* background: linear-gradient(276.03deg, #e9b7a7 7.57%, #151515 96.32%); */
    /* background: linear-gradient(276.03deg, #E1E1E1 7.57%, #BABABA 96.32%);
     */
    background: linear-gradient(276.03deg, #E1E1E1 7.57%, #BABABA 96.32%);
}
.OnlineState3{
    /* background:  linear-gradient(276.03deg, #ec3511 7.57%, #e70940 96.32%); */
    background: linear-gradient(276.03deg, #FFA089 7.57%, #FC6E58 96.32%);
}

.personGiftIcon{    
    right:0.27rem;    
    top:1rem;    
    width: 0.51rem;    
    height: 0.51rem;    
    z-index: 10000;    
    border-radius:0.51rem;    
    /* background: linear-gradient(180deg, #FF5757 0%, #FF36D3 100%);
    box-shadow: inset 0.5px 0.5px 4px rgba(255, 255, 255, 0.62), inset 2px 2px 15px rgba(255, 255, 255, 0.47), inset -4px -4px 20px #FF4B49; */
}
.personGiftIconImg{    
    width:80px;    
    height:80px; 
    margin-top:0.08rem;    
    display: inline-block;    
    /* background:url('../../assets/images/intimacy.png');  */
    /* background-size: cover;    */
    /*background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAASCAYAAAA+PQxvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARjSURBVHgB1ZXNa1xVGMaf985nZiadEBctapoEQcgusxGLiyQ7hSJm6QdqduKmunIlSXeutPkLzJ9QDW5EyEYQdGG0m6LFmbTaYpHmhszM/Z7X5z1nZoq01boRvPDOuffcc8/7O8/7MdDbd7v62+9dvX28iP/40qM723rr7rHe/OOVMnKdQ1na0OyqdrsbsrwcTheqNjgENJmOvxwvIOkrVhZ6tmRso6ntf7mA0yyU1y8e/y1E9842t9xGUQh9Q/TGzQMEwTpKgW3ZQ5RsyMpybwzSmkLcOH1X4/gDpEkbSQIk0Qmy9GvJs89H8eBMkCQXtcjWUOQQEYWUfkC5uiuvvrT3AMRPv37CRe+5M4zIPyqWRa/9vI5ADlAq0aX4F1m+h8HgMp5fvWcgo97wI0nydxDHEJpGQyCKgJSWxLQhhKMmESRPFSMVVGuKRotj431548UrDuC7a+soz3zKQy+546lBjPZk9dktcQu+v36Fk5eojBe6KGimNL5A+9yJlluviakQxarDgWBoIAOaAQwV8VDccxyppkMRO4yBNNvA3PwJzp/dQ732NoLS3PTAdgUaIpWOXFjpiXZvUZHSAU8B3DsxNSwmcM8Bwzd/nh/WnQJCEPT75lR10BfhaAAauTm+70NjrhtxjzJBZtuC+bPA0+eAJ1oewA5rY3sWmKkZzlVZfnKTs/khhpQ3y/iSixv1MQhVEb6uNLkxQ15wNs1FM86nuTNNUtE4FcQp1UgIlHjYAS2OxeaQ8l0/8XuaVSrA7BiK6zGIjpw4rkqG8a6DGUResuYMgWb8RwyRehM4oJESRkw5MSg35iopMz9NCZsJcoLau0K95QZQBerc00JjcBH9xXEPp6nLn7KPVbKDJFgj5ur91KaVPAjykRuFmzqg3M9pXnCODnMqlbu8Yn748yHg1lLxLiwcpnBm38lk/5CbbcoLnZ77wn6k0wlRrW4ym0Pn2PLEGanz1CcvE1BdqaklvE6lpkBuzu5tu1JZmR9MVrag6oxXwilbeMvHOVgr7RLicHLu8lSBLGZGV+dQq3oVzCk/kJjg2pzCeAa1CKtamapnMggxBaUk7B9MRCZjg7lQY8616z4kpkx5UjX6l04eTG7kOdKl6WUkqacuvFNkp7y3ZCv8s/pmqradbWgJ7RxUoJW6B7CybZ3hPQ/QoiqzlfsFwFyin57z9TAQB3Ohs4O82KL1vIxmTMKcZW0lOYEhgPhSpDaBU0AYBjEFmgSYJUiTQC2CPNUaNy4XmpD78bDK3uFzY+obj7j04Jsl1GrPMNBHsHI/rb/JUv1QEnbOgTWwSH1Ti1jSqStx9iOfDxaORj1ksm9giSPKS9yRY6UnG53wYf4eCeJgVEvjNerGr66/TMcfs28s6rjdu1Caek7fMUilcsic2JStjR4e8/onEHlg3f6Piyiybcb4LRdva4SWTz5UIXNmlwA7+JeXPO5CVyn8V53C7X+7hFgvsZms8c/fyv4zNrE9QoT4P19/Ap6tkq2GkPCzAAAAAElFTkSuQmCC) no-repeat; */
} 
.personGiftIconText{
    position: absolute;
    top: 45px;
}
/* .personGiftIconImg{
    width:0.34rem;
    height:0.18rem;
    margin-top:0.08rem;
    background-size: cover;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAASCAYAAAA+PQxvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARjSURBVHgB1ZXNa1xVGMaf985nZiadEBctapoEQcgusxGLiyQ7hSJm6QdqduKmunIlSXeutPkLzJ9QDW5EyEYQdGG0m6LFmbTaYpHmhszM/Z7X5z1nZoq01boRvPDOuffcc8/7O8/7MdDbd7v62+9dvX28iP/40qM723rr7rHe/OOVMnKdQ1na0OyqdrsbsrwcTheqNjgENJmOvxwvIOkrVhZ6tmRso6ntf7mA0yyU1y8e/y1E9842t9xGUQh9Q/TGzQMEwTpKgW3ZQ5RsyMpybwzSmkLcOH1X4/gDpEkbSQIk0Qmy9GvJs89H8eBMkCQXtcjWUOQQEYWUfkC5uiuvvrT3AMRPv37CRe+5M4zIPyqWRa/9vI5ADlAq0aX4F1m+h8HgMp5fvWcgo97wI0nydxDHEJpGQyCKgJSWxLQhhKMmESRPFSMVVGuKRotj431548UrDuC7a+soz3zKQy+546lBjPZk9dktcQu+v36Fk5eojBe6KGimNL5A+9yJlluviakQxarDgWBoIAOaAQwV8VDccxyppkMRO4yBNNvA3PwJzp/dQ732NoLS3PTAdgUaIpWOXFjpiXZvUZHSAU8B3DsxNSwmcM8Bwzd/nh/WnQJCEPT75lR10BfhaAAauTm+70NjrhtxjzJBZtuC+bPA0+eAJ1oewA5rY3sWmKkZzlVZfnKTs/khhpQ3y/iSixv1MQhVEb6uNLkxQ15wNs1FM86nuTNNUtE4FcQp1UgIlHjYAS2OxeaQ8l0/8XuaVSrA7BiK6zGIjpw4rkqG8a6DGUResuYMgWb8RwyRehM4oJESRkw5MSg35iopMz9NCZsJcoLau0K95QZQBerc00JjcBH9xXEPp6nLn7KPVbKDJFgj5ur91KaVPAjykRuFmzqg3M9pXnCODnMqlbu8Yn748yHg1lLxLiwcpnBm38lk/5CbbcoLnZ77wn6k0wlRrW4ym0Pn2PLEGanz1CcvE1BdqaklvE6lpkBuzu5tu1JZmR9MVrag6oxXwilbeMvHOVgr7RLicHLu8lSBLGZGV+dQq3oVzCk/kJjg2pzCeAa1CKtamapnMggxBaUk7B9MRCZjg7lQY8616z4kpkx5UjX6l04eTG7kOdKl6WUkqacuvFNkp7y3ZCv8s/pmqradbWgJ7RxUoJW6B7CybZ3hPQ/QoiqzlfsFwFyin57z9TAQB3Ohs4O82KL1vIxmTMKcZW0lOYEhgPhSpDaBU0AYBjEFmgSYJUiTQC2CPNUaNy4XmpD78bDK3uFzY+obj7j04Jsl1GrPMNBHsHI/rb/JUv1QEnbOgTWwSH1Ti1jSqStx9iOfDxaORj1ksm9giSPKS9yRY6UnG53wYf4eCeJgVEvjNerGr66/TMcfs28s6rjdu1Caek7fMUilcsic2JStjR4e8/onEHlg3f6Piyiybcb4LRdva4SWTz5UIXNmlwA7+JeXPO5CVyn8V53C7X+7hFgvsZms8c/fyv4zNrE9QoT4P19/Ap6tkq2GkPCzAAAAAElFTkSuQmCC) no-repeat;
} */
.personGiftIcon>div:last-child{
    line-height: 0.21rem;
    letter-spacing: -0.408px;
}

.person-menu-wrapper {
    
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 100%;
}
.person-menu-block {
    
    display: inline-block;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #3f45ff;
    border-radius: 4px;
    width: 120px;
    /* height: 120px; */
    background-color: #fff;
    border-radius: 4px;
    
}
.person-menu-block-item {
    color:black;
    padding: 0.05rem;
}

.person-nav {
    position: fixed;
    top: 0;
    /* opacity: 1; */
    background: #ffffff00;
    z-index: 100;
    width: 100%;
    height: 90px;
}

.model-status {
    width: 100%;
    height: 68px;
    /* background-color: red; */
    background-color: transparent;
    position: fixed;
    animation-name: model-status-keyframes;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-direction: alternate;
    z-index:10000;
  }
  .model-status-backimg {
    width: 322px;
    height: 68px;
  }
  .model-status>div{
    position: relative;
    width: 100%;
    height: 80px;
  }
  .model-status-back {
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    display: flex;
    justify-content: center;
  }
  .model-status-container{
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:14px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .model-status-container>img {
    height: 36px;
    width: 36px;
    object-fit: cover;
    border-radius: 18px;
  }
  
.model-status-container>div {
    /* height: 42px; */
    width: 248px;
    }

  @keyframes model-status-keyframes {
    0%   { left:0; bottom:-50px;}
    10%  { left:0; bottom:104px;}
    100% { left:0; bottom:104px;}
  }

  .video-cover {
    position: absolute;
    inset: 0px;
    background-color: #ffffff16;
  }
  .video-play-btn {
    width: 60px;
    height: 60px;
    
  }

  .p-a-video-cover-container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .p-a-video-cover{
    width: 0.72rem;
    object-fit: cover;
    height: 0.72rem;
    position: absolute;
    border-radius: 0.12rem;
  }
  .p-a-video-cover-btn{
    width: 30px;
    top: 30px;
    z-index: 1;
  }