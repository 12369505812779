.emoji-mask,.intimacy-mask{
    width: 100%;
    height:100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index:999999;
}

.emoji-container{
    width:100%;
    padding:0rem 0rem 0.32rem 0rem;
    background: linear-gradient(180deg, rgb(120,28,120) 0%, rgb(60,29,128) 100%);
    position: fixed;
    z-index:999;
    left: 0;
    bottom:0px;
    border-radius: 0.12rem 0.12rem 0px 0px;
}
.emoji-title{
    margin-top:0.15rem;
    margin-left:0.15rem;
    color: #F3B44A;
}

.emoji-board{
    width:3.6rem;
    margin:0.16rem auto;
    display: grid;
    grid-template-columns: repeat(4,calc(3.51rem/ 4));
    gap:0.06rem;
}

.emoji-board>div>img{
    display: inline-block;
    object-fit: cover;
    width:0.46rem;
    height:0.46rem;
}
.emoji-board-item span{
    text-align: center;
    display: inline-block;
    text-align: center;
}

.selected-emoji {
    border-width: 1px;
    border-color: rgb(235, 13, 217);
    border-style: solid;
}

.emoji-pagation{
    margin-top:4px;
    margin-bottom:4px;
}

.charge-tools{
    margin-left:0.12rem;
}
.charge-tools>span{
    display: inline-block;
}
.charge-text{
    color:#F7BC44;
}
.layout-ml4{
    margin-left:0.04rem;
}
.emoji-button{
    width:0.98rem;
    height: 0.22rem;
    border-radius:0.2rem;
    overflow: hidden;
    margin-right:0.34rem;
}
.emoji-button-item1{
    background-color: #363442;
    flex:1;
}
.emoji-button-item2{
    background-color: #FE446B;
    flex:1;
}
.emoji-button-item1 i{
    transform: rotate(90deg);
}
.emoji-button-item1 span{
    display: inline-block;
    margin-right:0.04rem;
}


.intimacy-box{
    width: 2.98rem;
    padding-bottom: 0.28rem;
    border-radius: 0.12rem;
    background-color: #fff;
    margin:1rem auto;
}
.intimacy-box-img{
    width:0.54rem;height:0.54rem;
    border-radius: 0.54rem;
    overflow: hidden;
    border: 1px solid #fff;
}
.intimacy-box-img:first-child{
    z-index:222;
    margin-right:-0.1rem;
}
.intimacy-box-img:last-child{
    z-index:666;
}
.intimacy-box>div:first-child{
    position: relative;
    margin-top:-0.27rem;
}
.intimacy-box>div{
   width:2.58rem;
}
.intimacy-title{
    line-height: 0.21rem;
}
.intimacy-title span{
    line-height: 0.43rem;
}

.intimacy-process{
    background: #C4C4C4;
    border-radius: 0.12rem;
    height:0.04rem;
    margin-top:0.12rem;
}
.intimacy-process-item{
    height:100%;border-radius: 0.12rem;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    transition: all 0.2s linear;
}
.intimacy-value{
    margin-top: 0.08rem;
}
.intimacy-value>div:first-child{
    margin-right:0.04rem;
}
.intimacy-remark{
    color: #666;
    margin-top: 0.2rem;
}
.intimacy-btn{
    height: 0.4rem;
    border-radius: 0.4rem;
    margin-top:0.3rem;
}
.intimacy-btn>div{
    margin-right:0.03rem;
}
.intimacy-btn>span{
    margin-left:0.03rem;
    display: inline-block;
}
.intimacy-wx-id{
    margin-top:0.3rem;
}

.intimacy-info{
    margin-top: 0.15rem;
}

.emoji-pagation-cxt{
    width:0.5rem;
    height:2px;
    border-radius:2px;
    background: #473E5B;
    overflow: hidden;
}
.emoji-pagation-item{
    height:2px;
    background: #473E5B;
}
.emoji-pagation-item-active{
    background-color: #fff;
}

.emoji-number-popup {
    position: absolute;
    width: 138px;
    height: 154px;
    right: 8px;
    bottom: 80px;
    background: #FFFFFF;
    border-radius: 8px;
}
.emoji-number-item {
    display: flex;
    flex-direction: row;
    height: 44px;
    align-items: center;
    justify-content: center;
}

.emoji-number-item-number {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FE446B;
}

.emoji-number-color {
    color: #FE446B;
}
.emoji-num-input-dialog {
    bottom: 0;
    position: absolute;
    font-size: 13px;
    right: 0;
    left: 0;
    padding:10px;
}

.emoji-num-input-dialog>div:first-child{
    display: flex;
}

.emoji-num-input-dialog input{
    flex: 1;
}
.emoji-num-input-dialog button{
    width: 82px;
    height: 42px;
    left: 0px;
    top: 0px;
    border: none;
    color: white;
    background: #FE446B;
}

.emoji-icon {
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.emoji-icon span {
    font-size: 25px;
    line-height: 25px;
}

.emoji-input-container{
    padding-left: 5%;
    padding-right: 5%;
}

.emoji-input-container input {
    width: 100%;
}
.emoji-send-btn {
    width: 42px;
    height: 24px;
    margin-right: 20px;
}