.gift-shower{
    height:0.34rem;
    bottom:3.2rem;
    left: 0;
    z-index: 1000;
}
.gift-shower-bg{
    width:2.11rem;
    height:100%;
    border-radius:0.34rem;
    transform: translateX(-2.11rem);
    opacity:0;
}
.send-gift-img{
    display: inline-block;
    object-fit: cover;
    width: 100%;
    height:100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index:-1;
}
.gift-number{
    height:100%;
    margin-left:0.16rem;
}
.gift-unit-img{
    width:0.13rem;
    height:0.11rem;
    display: inline-block;
}
.gift-number-img{
    width:0.14rem;
    height:0.2rem;
    display: inline-block;
}
.send-gift-icon{
    width:0.32rem;
    height:0.32rem;
    border-radius: 0.32rem;
    overflow: hidden;
    z-index:333;
}
.send-gift-text{
    margin-left:0.12rem;
}
.send-gift-text-i{
    width:0.28rem;
    height:0.28rem;
    display: inline-block;
    margin-left:0.08rem;
}
@keyframes bgfadein{
    10% {
        transform: translateX(-2rem);
        -webkit-transform:translateX(-2rem);
        opacity:0.1;
    }
    25% {
        -webkit-transform: translateX(-1.8rem);
        opacity:0.3;
        transform: translateX(-1.8rem);
    }
    35% {
        -webkit-transform: translateX(-1.5rem);
        opacity:0.5;
        transform: translateX(-1.5rem);
    }
    45%{
        -webkit-transform: translateX(-1.2rem);
        opacity:0.6;
        transform: translateX(-1.2rem);
    }
    55% {
        -webkit-transform: translateX(-0.9rem);
        opacity:0.7;
        transform: translateX(-0.9rem);
    }
    65% {
        -webkit-transform: translateX(-0.6rem);
        opacity:0.8;
        transform: translateX(-0.6rem);
    }
    85% {
        -webkit-transform: translateX(-0.3rem);
        opacity:0.9;
        transform: translateX(-0.3rem);
    }
    100% {
        -webkit-transform: translateX(0rem);
        opacity:1;
        transform: translateX(0rem);
    }
}


@keyframes bgfadeout{
    0% {
        -webkit-transform: translateX(0rem);
        opacity:1;
        transform: translateX(0rem);
    }
    15% {
        -webkit-transform: translateX(-0.3rem);
        opacity:0.9;
        transform: translateX(-0.3rem);
    }
    30% {
        -webkit-transform: translateX(-0.6rem);
        opacity:0.8;
        transform: translateX(-0.6rem);
    }
    45% {
        -webkit-transform: translateX(-0.9rem);
        opacity:0.7;
        transform: translateX(-0.9rem);
    }
    55%{
        -webkit-transform: translateX(-1.2rem);
        opacity:0.6;
        transform: translateX(-1.2rem);
    }
    70% {
        -webkit-transform: translateX(-1.5rem);
        opacity:0.5;
        transform: translateX(-1.5rem);
    }
    85% {
        -webkit-transform: translateX(-1.8rem);
        opacity:0.3;
        transform: translateX(-1.8rem);
    }
    100% {
        transform: translateX(-2.11rem);
        -webkit-transform:translateX(-2.11rem);
        opacity:0;
    }
}

@keyframes numfadein{
    10% {
        opacity:0.1;
        transform: scale(0.8);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
    25% {
       opacity:0.3;
       transform: scale(0.9);
       -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
    35% {
        opacity:0.5;
        transform: scale(1.0);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
    45%{
       opacity:0.6;
       transform: scale(1.1);
       -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
    55% {
        opacity:0.7;
        transform: scale(1.2);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
    65% {
        opacity:0.8;
        transform: scale(1.2);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
    85% {
        opacity:0.9;
        transform: scale(1.1);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
    95% {
        opacity:0.9;
        transform: scale(1.06);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
    100% {
        opacity:1;
        transform: scale(1.0);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
    }
}

.gift-shower-fadein{
    -webkit-animation:bgfadein 0.5s linear both;
    animation:bgfadein 0.5s  linear both;
}
.gift-shower-fadeout{
    -webkit-animation:bgfadeout 0.5s linear backwards;
    animation:bgfadeout 0.5s linear backwards;
}
.gift-shower-shake{
    -webkit-animation:numfadein 0.6s linear both;
    animation:numfadein 0.6s  linear both;
}
.gift-number-hide{
    opacity: 0;
}
.gift-number-show{
    opacity: 1;
}