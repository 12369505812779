.cash-card{
    background: linear-gradient(284.17deg, #FF7171 9.85%, #FFCE94 100%);
    border-radius: 0.12rem;
    width:3.35rem;height:1.05rem;
    margin-bottom:0.4rem;
}
.hide-x{
    overflow-x: hidden;
}
.layout-mt79{
    margin:0.79rem auto;
}
.font-size32{
    font-size:0.32rem;
}
.cash-card>div:first-child{
    line-height: 0.21rem;
}
.cash-card>div:last-child{
    line-height: 0.45rem;
}
.cash-form-item{
    width:3.35rem;
    margin-bottom:0.16rem;
}
.cash-form-input{
    width:100%;
    height: 0.44rem;
    background: #F9FAFC;
    border-radius: 0.12rem;
    margin-top:0.04rem;
}
.cash-form-item>div:first-child>span{
    display: inline-block;
    margin-left:0.08rem;
}
.cash-input-large{
    width:3.21rem;height:0.3rem;
    outline: none;
    border: none;
    background-color: transparent;
    padding-left:0.07rem;
}

.cash-input-small{
    width:2.2rem;height:0.3rem;
    outline: none;
    border: none;
    padding-left:0.07rem;
    background-color: transparent;
}
.cash-button{
    width: 0.68rem;height: 0.28rem;
    border-radius: 0.44rem;
    text-align: center;
    line-height:0.28rem;
}
.cash-button:active,.cash-button-send:active,.cash-submit:active{
    opacity: 0.65;
}
.cash-form-input>::placeholder{
     color: #acacac;
}
.cash-button-send{
    width: 0.72rem;
}
.cash-submit{
    width:3.35rem;
    height:0.48rem;
    border-radius:0.48rem;
    text-align: center;
    line-height: 0.48rem;
    margin-top:0.17rem;
}