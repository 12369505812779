
.live-page{
    padding-bottom: 1.2rem;
    overflow-y: scroll;
    height: 95vh;
    /* margin-top: 50px; */
}
.live-banner{
    width: 3.55rem;
    height: 1.02rem;
    border-radius:8px;
    /* overflow: hidden; */
    margin-top:0.2rem;
}

.live-banner>img{
    width: 100%;
    height: 100%;
    display: inline-block;
    object-fit: cover;
}

.first_live_icon img{
    position: absolute;
    width: 207px;
    height: 88px;
    left: 204px;
    top: 75px;
}

.first_live_ok_btn {
    box-sizing: border-box;
  position: absolute;
  width: 139px;
  height: 45px;
  left: 50%;
  top: 65%;
  border: 2px solid #FFFFFF;
  border-radius: 35px;
  cursor: pointer;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0px);
}

.nav-bar-container::-webkit-scrollbar { width: 0 !important }

.live-home-btn {
    padding:0px 10px;
}
.live-home-btn-cover {
    position: absolute;
    right: 20px;
    top:55px;
    width: 64px;
    height: 25px;
    z-index: 10001;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.location-auth-bar {
    background: rgba(253, 102, 253, 0.15);
    border-radius: 12px;
    
    width: calc(100vw - 16px);
    margin-top: 20px;
    /* padding-right: 60px; */
    padding: 4px 0px;
    height: 39px;
    max-height: 39px;
    margin-left: 8px;
    margin-right: 8px;
}

.location-auth-bar .auth-btn {
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 30px;
    padding: 4px 12px;
    color: white;
    margin-right: 15px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    
}

.location-auth-bar .auth-desc {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
}
.location-auth-bar img {
    width: 19px;
    height: 19px;
    display: block;
    margin-left: 8px;
}

.return-top {
    position: fixed;
    width: 52px;
    height: 52px;
    bottom: 160px;
    right: 52px;
    z-index: 100;
}

.live-error {

}

.live-error-icon {
    width: 32px;
    height: 32px;
}

.live-error-hint {
    font-size: 15px;
    color:#666;
}