
.gift-item{
    width:0.7rem;
    margin-bottom:0.28rem;
}

.black-remove{
    width: 0.50rem;
    height: 0.23rem;
    border-radius: 0.35rem;
    text-align: center;
    line-height: 0.23rem;
}
.black-userid{
    margin-left: 0.1rem;
}
.black-userid>div:first-child{
    line-height: 0.21rem;
}
.black-userid>div:last-child{
    color: #9EA6B9;
    line-height: 0.17rem;
}
.black-remove:active{
    opacity: 0.65;
}

.withdraw-record-right {
    max-width: 1rem;
}