.msg-item-container{
    margin-bottom: 0.12rem;
}
.msg-item-level{
    background: linear-gradient(105.28deg, #8E58FF 9.87%, #C76CFF 100%);
    border-radius: 0.33rem;
    height:0.15rem;
    padding: 2px 4px;
    width: 0.3rem;
}

.layout-ml8{
    margin-left: 0.08rem;
}
.msg-item-tag-bg1{
    background: #B897FF;
}
.msg-item-tag{
    padding: 0.02rem 0.04rem;
    border-radius: 3px;
    margin-left: 0.08rem;
}
.msg-item-tag-bg2{
    background: #FF97E2;
}
.msg-item-tag-bg3{
    background: linear-gradient(143.41deg, #AECDFF 14.26%, #92BBFF 87.71%);
}
.msg-item-tag-bg4{
    background: linear-gradient(146.69deg, #A7F0C7 18%, #92E9B3 88.79%);
}