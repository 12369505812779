.personData {
    padding-top: 50px;
}
.adm-nav-bar-right{
    color:red
}
.personData999999{
    color: #999999;
}
.personDataTr{
    font-size: 15px;
    padding:10px 20px;
    height: 47px;
}
.personDataTrLeft{
    float: left;
    margin-top: 10px;
}
.personDataTrRight{
    float: right;
    padding-top: 10px;
}
.pt-0{
    padding: none !important;
}
.personDataTrRightImg{
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
}

.right-menu>i {
    transform: rotate(180deg);
    display: inline-block;
    margin-right: 0.21rem;
    color: #C2C2C2;
}