.circle-item-head{
    height: 100%;
    width: 76px;
    object-fit: cover;
    margin-right: 0.1rem;
    border-radius: 0.1rem;
}
.circle-is-update {
    background: #FF8686;
    color:white;
    border-radius: 4px;
    width: 39px;
    height: 19px;
    line-height: 18px;
    font-size: 10px;
    margin-bottom: 4px;
}

.circle-container {
    overflow:scroll;
    position:absolute;
    left:0;
    right:0;
    bottom:50px;
    top:54px;
    padding-bottom:1rem;
    padding-top: 0.2rem;
    background-color: #f5f5f5;
}

.circle-purchase-page {
    padding-top: 0.1rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding-bottom: 120px;
    overflow: scroll;
}
    
.circle-purchase-banner {
    padding-top: 0.36rem;
    position: relative;
}

.circle-purchase-banner img {
    object-fit: cover;
    height: 2.5rem;
    width: 100%;
}

.circle-purchase-head {
    position: absolute;
    top:0;left:0;right:0;bottom:0;
    background-color: #00000064;
    padding: 20px;
}

.circle-purchase-head img {
    height: 61px;
    width: 61px;
    left: 20px;
    top: 132px;
    border-radius: 12px;

}
.circle-head-btn{
    padding-bottom: 20px;
}

.circle-head-btn img {
    width: 38px;
    height: 38px;
    margin: 10px;
    object-fit: contain;
}

.circle-purchase-content {
    /* z-index: 10000; */
    background-color: white;
    /* margin: 0.1rem; */
    padding: 0.1rem;
    /* position: absolute; */
    top: 2.8rem;
    /* width: 100%; */
    right: 0;
    left: 0;
    border-radius: 0.2rem;
}

.circle-pur-section {
    padding:0.1rem;
}

.circle-pur-section img {
    width: 0.5rem;
    height: 0.5rem;
    /* object-fit: contain; */
    object-fit: cover;
    border-radius: 100%;
}

.circle-purchase-bottom {
    position: fixed;
    background-color: white;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0.8rem;
    padding-bottom: 0.2rem;
    padding-left: 0.2rem;
}


.c-p-submit {
    width: 118px;
    height: 40px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 15px;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
}

.c-p-dialog {
    width: 342px;
    height: 263px;
    left: 0px;
    top: 0px;
    
    background: #FFFFFF;
    border-radius: 12px;
}
.c-p-dialog-2 {
    width: 284px;
    height: 286px;
    left: 0px;
    top: 0px;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
}

.c-p-dialog-head-2{
    width: 80px;
    height: 80px;
    border-radius: 40px;
    position: absolute;
    top: 4px;
    left: 102px;
    object-fit: cover;
}
.c-p-dialog-2-desc {
    position: absolute;
    left:0;right:0;
    top:100px;
}
.c-p-dialog-2-desc2 {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;

    /* identical to box height */

    color: #000000;

    opacity: 0.3;
}

.c-p-dialog-img {
    width: 284px;
    height: 286px;
}


.c-p-dialog-title{
    margin-top: 14px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.c-p-dialog-content {
    margin-top: 9px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin:0px 15px;
}
.last-line {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.24px;
}

.c-p-confirm-submit {
    width: 252px;
    height: 44px;
    left: 0px;
    top: 0px;
    
    /* Linear */
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    
}
.c-p-confirm-submit-container{
    position: absolute;
    bottom:76px;
    right:0;
    left:0;
}
.c-p-confirm-submit2-container {
    position: absolute;
    bottom: 20px;
    right:0;
    left:0;
}
.c-p-confirm-submit2 {
    box-sizing: border-box;

  
    width: 252px;
    height: 44px;
    left: 0px;
    top: 0px;
    
    opacity: 0.6;
    border: 1px solid #FC66FF;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#FC66FF;


}


.circle-content {
    /* z-index: 10000; */
    background-color: white;
    /* margin: 0.1rem; */
    position: absolute;
    top: 2.8rem;
    /* width: 100%; */
    right: 0;
    left: 0;
    border-radius: 0.2rem;
}



.circleList {
    padding-top: 0.3rem;
}

.circleListItem{
    margin-bottom:0.1rem;
    padding:0.18rem 0;
    background-color: #fff;
}

.circleListUserAvatar{
    width:0.42rem;height:0.42rem;border-radius:0.42rem;
    overflow: hidden;
    position: relative;
    margin-left:0.15rem;
}
.circleListUserAvatar>img{
    width: 100%;
    height: 100%;
    display: inline-block;
    object-fit: cover;
    position: absolute;
    left:0;
    top: 0;
}
.circleListUserOnline{
    border:2px solid #24E0BE
}
.circleListUserOnlineTip{
    width:0.6rem;height:0.32rem;
    background-color: #24E0BE;
    font-size:16px;
    color: #fff;
    text-align: center;
    line-height:0.32rem;
    position: absolute;
    left:0.108rem;
    bottom: -0.04rem;
    border-radius:0.32rem;
    zoom: 0.5;
}
.circleItemAlbums{
    margin-left: 0.15rem;
    margin-right: 0.15rem;
    margin-top: 0.02rem;
    position: relative;
}

.circlie-more-num{
    position: absolute;
    left: 2.6rem;
    bottom: 0.4rem;
    color: white;
}

.circleItemBack{
    background-color: #000;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    border-radius: 8px;
    margin-right: 0.08rem;
}

.circleItemImg{
    width: 1rem;
    height: 1rem;
    border-radius: 8px;
    overflow: hidden;
    display: inline-block;
    object-fit: cover;
    /* margin-right: 0.08rem; */
}

.circleItemImg.nopay {
    opacity:0.25;
    object-fit: contain;
    /* filter:alpha(opacity=60); */
    -webkit-mask : -webkit-gradient(linear, left top, right bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    /* -webkit-mask: url('../../assets/images/fog1.jpg'); */
}

.circleItemImg.free {
    /* opacity:1; */
    /* filter:alpha(opacity=60); */
    -webkit-mask : -webkit-gradient(linear, left top, right bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    /* -webkit-mask: url('../../assets/images/fog1.jpg'); */
}

.circle-nav-container {
    background-color: white;
    height: 80px;
    z-index: 10;
    position: sticky;
    top:30px;
}

.circle-item {
    width: calc(100% - 30px);;
    height: 76px;

    filter: drop-shadow(0px 3px 20px rgba(232, 237, 241, 0.9));
    border-radius: 8px;
    margin-bottom:20px;
    background-color: white;
}
.circle-item-lock{
    width: 91px;
    height: 26px;
    left: 0px;
    top: 0px;
    background: linear-gradient(91.22deg, #FC66FF19 2.91%, #FF5C5C19 95.46%);
    /* opacity: 0.1; */
    border-radius: 18px;
}

.circle-item-unlock-amount {
    color: #FFC200;
    font-size: 13px;
}

.circle-item-gold {
    color: #FC66FF;
    font-size: 11px;
}

.circle-opus-detail-page {
    background-color: white;
    height: 100vh;
    padding-top: 0px;
    overflow: scroll;
}

.circle-opus-detail-page .circleListItem {
    margin-top: 70px;
}
.c-o-d-split {
    border-bottom: #eff1f5 solid 1px;
}

.odc-list-item{
    padding-left: 20px;
    margin-top: 16px;
}

.odc-list-item img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    object-fit: cover;
}

.subcomment {
    margin-top: 8px;
}
.subcomment img {
    width: 23px;
    height: 23px;

}
.opus-comment-blank{
    height: 1rem;
}
.opus-comment-container {
    position: fixed;
    bottom: 0;
    height: 63px;
    background-color: white;
    left: 0;
    right: 0;
    padding-top: 20px;
}
.opus-comment-container input {
    width: 269px;
    height: 30px;
    left: 0px;
    top: 0px;

    background: #F9FAFC;
    border-radius: 78px;
    border:none;
    margin-right: 15px;
    padding-left:13px;
}

.opus-comment-container .submit {
    width: 55px;
    height: 29px;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 32px;
    color:white;
    font-size: 15px;
}

.my-circle-page {
    padding-top: 60px;
    height: 100%;
}
.my-circle-container {
    bottom:0px;
    padding-top: 50px;
}
.circle-item-1 {
    width: 50%;
    height: calc(50vw*1.32);
    border-radius: 8px;
    overflow: hidden;
    display: inline-block;
    object-fit: cover;
    margin-right: 0.08rem;
    position: relative;
}

.circle-video-cover-b {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}