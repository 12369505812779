.star-form-item{
    width:3.35rem;
}
.star-form-h{
    height:0.5rem;
}
.star-right-menu{
    transform: rotate(180deg);
    display: inline-block;
    color: #616060;
    font-size: 0.17rem;
    font-weight:500;
}
.star-right-menu{
    display: inline-block;
    margin-left:0.15rem;
}
.star-page{
    padding-bottom: 1.2rem;
    height: 100%;
    overflow: scroll;
}
.star-text{
    margin-right:0.1rem;
}