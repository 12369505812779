.web {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1001;
    padding-top: 20px;
}

.web-container {
    position: absolute;
    top:50px;
    height: 90%;
    width: 100%;
    border-width: 0px;
}