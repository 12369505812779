.rank-page{
    height: 100vh;
    overflow: scroll;
}
.rank-header{
    width: 3.23rem;
    height:0.5rem;
    margin: 0 auto;
}
.rank-nav{
    position:fixed;
    left: 0;
    top:0;
    padding-top: 40px;
    overflow: hidden;
    z-index: 333;
    background-color: #FC66FF;
}

.rank-line{
    height: 3px;width:0.2rem;
    border-radius:0.17rem;position: absolute;
    bottom:-2px;
    background-color: #fff;
}
.rank-line-box{
    width:100%;
    position: absolute;
    left:0;
    bottom:-6px;
}
.rank-header>div:last-child{
    margin-left: 0.43rem;
}
.rank-header>div>.layout-rel{
    margin-right: 0.2rem;
}
.rank-nav-bg{
    margin-top: 30px;
    height:3.6rem;
    z-index: 0;
}
.rank-nav-cxt{
    top: 0.6rem;
    left:0rem;
}
.rank-nav-switch{
    width: 1.83rem;
    height: 0.26rem;
    background:rgb(255, 255, 255,0.3);
    border-radius: 0.32rem;
    position: relative;
    z-index:1;
}
.rank-nav-item{
    height: 100%;
    height: 0.26rem;
    width:0.61rem;
}
.rank-nav-item-active{
     background: #FFFFFF;
     z-index: 555;
     border-radius: 0.32rem;
}
.rank-nav-item-1{
     left:0rem;
}
.rank-nav-item-2{
    left:0.61rem;
}
.rank-nav-item-3{
    left:1.22rem;
}
.rank-nav-person{margin-top:0.3rem;}
.rank-person-1{
    width:1.11rem;
}
.rank-person-2,.rank-person-3{
    width: 0.97rem;
}
.rank-person-2{
    margin-top:0.27rem;
}
.rank-person-1{
    margin-top:-0.38rem;
}
.rank-person-3{
    margin-top:0.3rem;
}

.rank-person-2>.rank-person-item>div:first-child{
    width:0.53rem;height:0.53rem;
    overflow: hidden;
    border-radius:0.53rem;
    border:2px solid #DADADA;
}
.rank-person-1>.rank-person-item>div:first-child{
    width:0.66rem;height:0.66rem;
    border-radius:0.66rem;
    overflow: hidden;
    border-radius:0.66rem;
    border: 2px solid #FFC875;
}
.rank-person-3>.rank-person-item>div:first-child{
    width:0.53rem;height:0.53rem;
    border-radius:0.53rem;
    overflow: hidden;
    border: 2px solid #E7B287;
}

.rank-person-2>.font-size13,.rank-person-1>.font-size13,.rank-person-3>.font-size13{
    line-height:0.18rem;
}
.mt8{
    margin-top:0.08rem;
}
.rank-nav-person>div>div>span{
    display: inline-block;
    margin-left:0.04rem;
}
.diadema-item{
    z-index:666;
    top:-0.07rem;
    left:50%;
    transform:translateX(-50%) translateY(-50%);
}
.nav-gold-icon{
    width:0.12rem;height:0.12rem;
    display: inline-block;
    border-radius:0.12rem;
    object-fit: cover;
}


/* rank list */
.rank-list-container{
    padding:0.08rem 0rem 0.32rem 0rem;
    border-radius: 0.12rem 0.12rem 0px 0px;
    position: relative;
    top: -0.2rem;
    overflow: hidden;
    background-color: #fff;
}
.rank-list-title{
   width:3.45rem;
}
.rank-list-cxt{
   width:3.45rem;
   margin-top:0.21rem;
}
.list-title-day{
    color: #a6a6a6;
}
.list-title-hot{
    display: inline-block;
    margin-left: 0.04rem;
    line-height: 0.14rem;
}
.layout-ml12{
    margin-left:0.12rem;
}
.rank-list-item{
    margin-bottom:0.13rem;
}
.rank-list-item span{
    display: inline-block;
    margin-left:4px;
}
.list-item-index{
    width:0.24rem;
    text-align: center;
}