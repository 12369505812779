.feedback-page {
    padding-top: 30px;
}

.feedback-textarea{
    width:3.19rem;
    height:1.4rem;
    border: none;
    background-color: #f8f8f8;
}
.feedback-textarea::placeholder{
     color: #999999;
}
.feedback-textarea::-ms-input-placeholder{
    color: #999999;
}
.feedback-textarea::-moz-placeholder{
    color: #999999;
}
.feedback-box{
    width:3.19rem;
    height:1.4rem;
    padding:0.05rem 0.05rem;
    border-radius: 0.12rem;
    border: 1px solid #f8f8f8;
    background-color: #f8f8f8;
}