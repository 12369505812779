@font-face {
  font-family: "iconfont"; /* Project id 3484755 */
  src: url('iconfont.woff2?t=1656259537373') format('woff2'),
       url('iconfont.woff?t=1656259537373') format('woff'),
       url('iconfont.ttf?t=1656259537373') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tongzhifill:before {
  content: "\e758";
}

.icon-15qingkong-1:before {
  content: "\e681";
}

.icon-xihuan:before {
  content: "\e8c3";
}

.icon-sousuo:before {
  content: "\e86e";
}

.icon-weixin:before {
  content: "\e60b";
}

.icon-gonggao:before {
  content: "\e620";
}

.icon-xiaoxi2:before {
  content: "\e6a1";
}

.icon-xiaoxi3:before {
  content: "\e719";
}

.icon-iconhuizong_huaban1fuben2:before {
  content: "\e60d";
}

.icon-more:before {
  content: "\e6ad";
}

.icon-shezhi:before {
  content: "\e8b8";
}

.icon-chongzhi:before {
  content: "\e61e";
}

.icon-quanzi:before {
  content: "\e6fa";
}

.icon-book:before {
  content: "\e69a";
}

.icon-shipin:before {
  content: "\e600";
}

.icon-shengriliwu:before {
  content: "\e601";
}

.icon-baobei:before {
  content: "\e602";
}

.icon-shenfenzheng:before {
  content: "\e64d";
}

.icon-qianbao:before {
  content: "\e627";
}

.icon-meiyanxianxing:before {
  content: "\e659";
}

.icon-fanhui:before {
  content: "\e64e";
}

.icon-icon:before {
  content: "\e668";
}

.icon-liwu:before {
  content: "\e685";
}

.icon-2:before {
  content: "\e680";
}

.icon-qinmifu:before {
  content: "\e62b";
}

.icon-lianxiren:before {
  content: "\e61f";
}

.icon-kefu:before {
  content: "\e88f";
}

.icon-liwu1:before {
  content: "\e6c7";
}

.icon-heimingdan:before {
  content: "\e65e";
}

.icon-home-fill:before {
  content: "\e867";
}

.icon-xiaoxi:before {
  content: "\e622";
}

.icon-liwu2:before {
  content: "\e613";
}

.icon-yejianmoshi:before {
  content: "\e611";
}

.icon-biaoqing:before {
  content: "\e61b";
}

.icon-weibiaoti--:before {
  content: "\e60e";
}

.icon-wode:before {
  content: "\e67b";
}

.icon-mohumopi:before {
  content: "\e634";
}

.icon-shipin1:before {
  content: "\e695";
}

.icon-faxian:before {
  content: "\e621";
}

.icon-xitongquanxian:before {
  content: "\e61d";
}

.icon-xiaoxi1:before {
  content: "\e62e";
}

.icon-icon-test:before {
  content: "\e6df";
}

.icon-guaduan:before {
  content: "\e640";
}

.icon-magic-:before {
  content: "\e713";
}

.icon-dongtai:before {
  content: "\e612";
}

.icon-wode1:before {
  content: "\e615";
}

.icon-wurao:before {
  content: "\e6dd";
}

.icon-jianpan:before {
  content: "\e661";
}

