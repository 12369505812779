
.fanItem{
    width:3.29rem;
    margin-bottom:0.28rem;
}


.fanIndex {
    width:26px;
    margin-right: 4px;
    display: flex;
    justify-content: center;
}

.fanIndex img {
    height: 25px;
    width: 25px;
}

.fan-head {
    width: 48px;
    height: 48px;
    border-radius: 24px;
}

.fan-head img {
    width: 47px;
    height: 47px;
    border-radius: 24px;
}

.black-remove{
    width: 0.50rem;
    height: 0.23rem;
    border-radius: 0.35rem;
    text-align: center;
    line-height: 0.23rem;
}
.black-userid{
    margin-left: 0.1rem;
}
.black-userid>div:first-child{
    line-height: 0.21rem;
}
.black-userid>div:last-child{
    color: #9EA6B9;
    line-height: 0.17rem;
}
.black-remove:active{
    opacity: 0.65;
}

.withdraw-record-right {
    max-width: 1rem;
}

.fan-list {
    height: 90vh;
    padding-bottom: 50px;
    overflow: scroll;
}