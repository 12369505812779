.msg-page{
    width: 100%;
    padding-bottom: 1.2rem;
    padding-top: 40px;
}
.msg-header{
    padding:0.15rem 0rem;
    margin-left:0.12rem;
    margin-right:0.12rem;
}
.msg-header i{
   font-size:0.2rem;
   font-weight:600;
   color: #000;
}
.msg-header .iconfont:last-child{
    margin-left:6px;
}
.msg-menu{
   width:3.35rem;
   margin:0.2rem auto;
}

.msg-menu>.msgItem {
    position: relative;
}

.msgMenuItem{
    width:0.56rem;height:0.56rem;border-radius:100%;
}

.msgMenuItem img {
    width: 50px;
    height: 50px;
}

.msg-bg1{
    background: linear-gradient(278.44deg, #68AEFE 3.29%, #8AD3FE 100%);
}
.msg-bg2{
    background: #FFE7C8
}
.msg-bg3{
    background: #24E0BE;
}
.msgMenuItem i{
    font-size:0.22rem;
    color: #fff;
}
.msgItem>.theme-font{
     margin-top:0.05rem;
}
.msg-list-container{

    width: 3.35rem;
    margin: 0.1rem auto;
    height: 70vh;
    overflow: scroll;
}

.msg-list-container::-webkit-scrollbar { width: 0 !important }


.msg-list-item{
     margin-top:0.12rem;
}
.msg-list-item>img{
    width:0.48rem;height:0.48rem;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
}
.msg-user-info{
   margin-left:12px;
   height:0.48rem;
   padding-bottom:0.12rem;
   border-bottom: 1px solid #ececec;
}
.msg-no-read{
    width:0.16rem;height: 0.16rem;border-radius: 0.16rem;
    background-color: red;
    overflow: hidden;
    text-align: center;
    line-height:0.16rem;
    margin-top:4px;
}

.msg-brief {
    max-height: 0.6rem;
    height: 0.2rem;
    overflow: hidden;
}

.notice-no-read {
    position: absolute;
    left: 60%;
    max-width: 0.32rem;
}

.msg-cancel-btn {
    width: 94px;
    height: 33px;
    left: 0px;
    top: 0px;

    background: #F0F2F7;
    border-radius: 45px;
    color:black;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msg-ok-btn {
    width: 94px;
    height: 33px;
    left: 0px;
    top: 0px;

    /* Linear */
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 45px;
    color:white;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msg-kefu {
    position: fixed;
    right: 0px;
    bottom: 100px;
    width: 100px;
    height: 100px;
}
.msg-kefu img {
    width: 60px;
    height: 60px;
}