html,body{width: 100%;margin:0;padding: 0;font-size: 50px;} 
body{
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
*{padding: 0;margin: 0;}
.fx-box{display: flex;}  
.fx-ai-c{align-items: center;} 
.fx-ai-e{align-items: flex-end;}
.fx-ai-s{align-items: flex-start;} 
.fx-jc-c{justify-content: center;}
.fx-jc-s{justify-content: flex-start;} 
.fx-ai-st{align-items: stretch;} 
.fx-jc-e{justify-content: flex-end;}  
.fx-jc-sb{justify-content:space-between} 
.fx-jc-sa{justify-content:space-around}  
.fx-fd-c{flex-direction: column;} 
.fx-fd-r{flex-direction: row;}
.fx-wp{flex-wrap: wrap} 
.full-r-w{width: 100%;}  
.full-r-h{height: 100%;} 
.full-a-w{width: 100vw;}  
.full-a-h{height: 100vh;}
.full-r-s{overflow-y: scroll;} 
.fx-1{flex: 1;}
.full-hide{overflow: hidden;}
.layout-rel{position: relative;}
.layout-abs{
  position: absolute;
}

.font-w4{
  font-weight:400;
}
.font-w5{
  font-weight:500;
}
.font-w6{
  font-weight:600;
}
.theme-font{
  font-family: 'PingFang SC';
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.12px;
}
.font-size5{
    font-size: 0.05rem;  
}
.font-size8{
    font-size: 0.08rem;  
}
.font-size10{
  font-size: 0.10rem;
}
.font-size11{
  font-size: 0.11rem;
}
.font-size13{
  font-size:0.13rem;
}
.font-size12{
  font-size:0.12rem;
}
.font-size15{
  font-size:0.15rem;
}
.font-size17{
  font-size: 0.17rem;
}
.font-size18{
  font-size: 0.18rem;
}
.font-size20{
  font-size:0.2rem;
}
.font-size24{
  font-size:0.24rem;
}
.font-size30{
  font-size:0.3rem;
}

.font-size-28px{
  font-size:28px;
}

.font-size-15px {
  font-size: 15px;
}

.font-color1{
  color: #404040;
}
.font-color2{
  color: #FD66F5;
}
.font-color3{
  color: #FFFFFF;
}
.font-color4{
  color: #9DA6BB;
}
.font-color5{
  color: #ACACAC;
}
.font-color6{
  color: #999;
}
.font-color7{
  color: #747474;
}
.font-color8{
  color: #999999;
}
.font-color9{
  color: #212121;
}
.font-color10{
  color: #969696;
}
.font-color11{
    color: #aa644e;
}

.font-color12 {
    color:#face35;
}
  

.text-nowrap {
    white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-center{
  text-align: center;
}

.layout-ml16{
  margin-left:0.16rem;
}

.icon-color-selected{
    color:#f3a2b9;
}
.btn-bg1{
  background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
}
.btn-bg2{
  background: linear-gradient(180deg, #763DEC 0%, #1E047E 100%);
  box-shadow: inset 0px -4px 12px rgba(255, 255, 255, 0.35);
}
.btn-bg3{
  background: linear-gradient(0deg, #D061FA 0%, #7417A0 100%);
  box-shadow: inset 0px -4px 12px rgba(255, 255, 255, 0.35);
}
.btn-bg4{
  background-color: #C8C8C8;;
}
.layout-mr8{
  margin-right:0.08rem;
}
.person-base-fans>div:first-child{
  margin-right: 0.34rem;
}
.layout-fx{
  position: fixed;
}

.intimacy-tip{
  color: #FF7373;
}
.intimacy-stip{
  color: #FF4242;
}

.fx-btn{
   left:0;
   bottom: 0.1rem;
   display: flex;
   justify-content: center;
   align-items: center;
}
.fx-submit{
  width:3.35rem;
  height:0.48rem;
  line-height:0.48rem;
  text-align: center;
  border-radius: 1.43rem;
  letter-spacing: -0.24px;
}
.fx-submit:active,.fx-submit:hover{
  opacity: 0.65;
}

.layout-mt24{
  margin-top: 0.24rem;
}
.layout-mt70{
  margin-top:0.7rem;
}
.layout-mt60{
  margin-top:0.6rem;
}


.search-icon {
    width: 1em;
    height: 1em;
    vertical-align: top;
    fill: currentColor;
    overflow: hidden;
    max-width: 0.3rem;
}

img.search-icon {
    width:20px;
    height: 20px;
}

.page-recharge {
  position: fixed;
  top: 112px;
  left: 0;
  right: 0;
}


.btn-cancel {
  width: 83px;
  height: 33px;
  left: 0px;
  top: 0px;

  border: 1px solid #ACACAC;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#ACACAC;
}

.btn-ok {
  width: 94px;
  height: 33px;
  left: 0px;
  top: 0px;

  /* Linear */
  background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
}


.model-notice-dialog{
  width: 283px;
  height: 167px;
  left: 0px;
  top: 0px;

  background: #FFFFFF;
  border-radius: 12px;
}

.model-notice-dialog .title {
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.23999999463558197px;
  text-align: center;
  padding-top: 15px;
}


.model-notice-dialog .desc {
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.23999999463558197px;
  text-align: left;
  padding: 15px 25px;
}


.model-notice-dialog .submit {
  width: 227px;
  height: 33px;
  left: 0px;
  top: 0px;

  /* Linear */
  background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
  border-radius: 45px;
}

.banner-dialog {
  width: 280px;
  height: 350px;
}

.banner-dialog img {
  width: 280px;
  height: 280px;
  object-fit: contain;
}

.banner-dialog-close img{
  height: 30px;
  width: 30px;
}


.promotion-logo {
  position: fixed;
  width: 87px;
  height: 74px;
  bottom: 220px;
  right: 35px;
  z-index: 100;

}