.active-page{
    width:100%;
    height: 95vh;
    overflow: scroll;
    padding-bottom: 2rem;
    background-color: #fafafa;
    padding-top: 0.63rem;
}
.activeListItemLevel{
    color:#FC66FF;
    border: 1px solid #FC66FF;
    width:0.38rem;height:0.18rem;
    border-radius:0.18rem;
    text-align: center;
    line-height:0.18rem;
    margin-left:0.1rem;
    margin-right:0.06rem;
}
.activeList{
    margin-top:0.2rem;
    /* padding-bottom: 1rem; */
}
.activeListItem{
    margin-bottom:0.1rem;
    padding:0.08rem 0;
    background-color: #fff;
}
.activeListUserAvatar{
    width:0.42rem;height:0.42rem;border-radius:0.42rem;
    overflow: hidden;
    position: relative;
    margin-left:0.15rem;
}
.activeListUserAvatar>img{
    width: 100%;
    height: 100%;
    display: inline-block;
    object-fit: cover;
    position: absolute;
    left:0;
    top: 0;
}
.activeListUserOnline{
    border:2px solid #24E0BE
}
.activeListUserOnlineTip{
    width:0.6rem;height:0.32rem;
    background-color: #24E0BE;
    font-size:16px;
    color: #fff;
    text-align: center;
    line-height:0.32rem;
    position: absolute;
    left:0.108rem;
    bottom: -0.04rem;
    border-radius:0.32rem;
    zoom: 0.5;
}
.userItemLevel{
    margin-left:0.15rem;
    margin-right:0.15rem;
}
.userItemLevel i{
    font-size:0.22rem;
    color: #5BE381;
}
.userItemMore{
    transform: rotate(270deg);
}
.userItemMore i{
    font-size:0.18rem;
    display: inline-block;
    color: #ACACAC;
}
.userItemMsg{
    padding:0.02rem 0.15rem;
}

.userItemAlbums{
    margin-left:0.15rem;
    margin-right:0.15rem;
    margin-top: 0.02rem;
}

.userItemAlbums video {
    width: 50%;
    height: calc(50vw * 1.32 );
    object-fit: cover;
}

.userItemPreview{
    margin-left:0.15rem;
    margin-right:0.15rem;
    margin-top: 0.05rem;
}
.userItemImg{
    width: 1rem;height: 1rem;
    border-radius:8px;
    overflow: hidden;
    display: inline-block;
    object-fit: cover;
    margin-right:0.08rem;
}

.userItemImg-1{
    width: 50%;
    height: calc(50vw*1.32);
    border-radius:8px;
    overflow: hidden;
    display: inline-block;
    object-fit: cover;
    margin-right:0.08rem;
}

.userItemP i{
    color: #9DA6BB;
    font-size: 0.18rem;
}

.userItemP .icon-color-selected{
    color: #fDA6BB;
    font-size: 0.18rem;
}

.userItemP>div:first-child{
    margin-right:6px;
}

.userItemP>div>i{
    display: inline-block;
    margin-right:3px;
}

.active-o2obtn {
    position: absolute;
    right: 10px;
    top: 25px;
}