.vip-dialog {
    width: 306px;
    min-height: 354px;
    max-height: 403px;
    left: 0px;
    top: 0px;

    background: linear-gradient(136.8deg, #000000 1.36%, #636363 102.56%);
    border-radius: 12px;
    padding: 22px;
}

.vip-dialog .icon {
    width: 114px;
    height: 82px;
    margin-top: 6px;
}

.vip-dialog .title {
    width: 60px;
    height: 21px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;

    /* identical to box height */
    text-align: center;
    letter-spacing: -0.24px;

    color: #FFECBB;
}

.vip-dialog .submit {
    width: 252px;
    height: 44px;
    left: 0px;
    top: 0px;

    background: linear-gradient(91.22deg, #FFEEC1 2.91%, #FFE6A8 95.46%);
    border-radius: 40px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;

    /* identical to box height */
    letter-spacing: -0.24px;

    color: #000000;
    
}

.vip-dialog .small-header {
    font-family: 'PingFang SC';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 18px;
letter-spacing: -0.24px;
color: #FFFFFF;
}

.vip-dialog .content {
    font-family: 'PingFang SC';
font-style: normal;
font-weight: 100;
font-size: 13px;
line-height: 18px;
letter-spacing: -0.24px;

color: #FFFFFF;
margin-bottom: 12px;
}