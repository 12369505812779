.set-page {
    padding-top: 35px;
    height: 80vh;
}
.set-page-menu{
    width:3.33rem;
}
.set-page-menuItem{
    height:0.4rem;
    letter-spacing: -0.24px;
}
.set-page-menuItem i{
    transform: rotate(180deg);
    display: inline-block;
    color: #C2C2C2;
    font-size: 0.17rem;
}

.set-submit {
    margin-bottom: 100px;
}