.pay-container {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1001;
}

.pay-web-container {
    position: absolute;
    top:50px;
    height: 90%;
    width: 100%;
    border-width: 0px;
}

.pay-modal {
    padding-top: 50px;
}