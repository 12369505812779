.mine-page{
    width: 100%;
    height: 95vh;
    overflow: scroll;
    padding-bottom:1.2rem;
}
.mine-header{
    /* background: linear-gradient(180deg, #FF93F9 0%, rgba(255, 147, 249, 0) 100%); */
    background: url(../../assets/images/mine-back.png);
    height:1.86rem;width: 100%;
    background-size: cover;
}
.avatars{
    width: 0.71rem;
    height: 0.71rem;
    border-radius:100%;
    overflow: hidden;
}
.user-avatar{
    width: 0.71rem;
    height: 0.71rem;
    border-radius:100%;
    object-fit:cover;
}
.avatars>div{
    display: inline-block;
    bottom: 0;
    left: 0;
    height:0.20rem;
    width:100%;
    background-color: rgba(0, 0, 0, 0.25);
    line-height:0.2rem;
}
.mine-top{
    padding-top:0.4rem;
    margin-left: 0.2rem;
    margin-right:0.2rem;
}
.mine-edit{
    /* width: 1rem;
    height: 0.46rem;
    background: #F3F3F3;
    border-radius: 0.94rem;
    font-size: 0.2rem;
    letter-spacing: -0.48px;
    color: #A3A3A3;
    zoom: 0.5; */
    width: 53px;
    height: 23px;
    background: #F3F3F3;
    border-radius: 0.94rem;
    font-size: 0.2rem;
    letter-spacing: -0.48px;
    color: #A3A3A3;
}
.mine-edit .iconfont{
    font-size:10px;
    transform: rotate(180deg);
}
.mine-edit>span{
    margin-left:8px;
    font-size: 13px;
    display: inline-block;
    white-space: nowrap;
}
.mine-edit>i{
    display: inline-block;
    margin-right:9px;
}

.nickname{
    font-style: normal;
    font-weight: 500;
    font-size: 0.20rem;
    letter-spacing: -0.24px;
    padding-bottom:0.05rem;
}
.user-id{
    font-style: normal;
    font-weight: 500;
    font-size: 0.13rem;
    letter-spacing: -0.24px;
    color: #999999;
}

.id-icon{
    width:0.12rem;height:0.12rem;
    border: 1px solid #999999;
    border-radius:100%;
    display: inline-block;
    margin-left:4px;
    text-align: center;
    line-height:0.1rem;
}
.id-icon>.iconfont{
    font-size:0.1rem;
}

.mine-center{
    margin-top:0.23rem;
}

.mine-menu{
    width: 3.35rem;
    height: 0.96rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgba(232, 237, 241, 0.9);
    border-radius: 0.12rem;
    margin:0 auto;
}

.menuItem{
    width:0.44rem;height:0.44rem;
    border-radius:0.12rem;
}
.menu-bg1{
    background: linear-gradient(180deg, #FFD9B7 0%, #FFB967 100%);
}
.menu-bg2{
    background: linear-gradient(180deg, #FFBEFD 0%, #FF7BFC 100%);
}
.menu-bg3{
    background: linear-gradient(180deg, #B6E9FF 0%, #6CD3FF 100%);
}
.menu-bg4{
    background: linear-gradient(180deg, #CEA8FF 0%, #A864FF 100%);
}

.menuItem>.theme-font{
    margin-top:4px;
}
.menuItem>img{
    width:0.24rem;height:0.24rem;border-radius:100%;
}
.menuItem>i{
    font-size:0.2rem;
    color: #FFFFFF;
}

.menu-list{
    width: 3.35rem;
    padding-top: 0.18rem;
    padding-bottom:1rem;
    margin:20px 20px;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgba(232, 237, 241, 0.9);
    border-radius: 0.12rem;
}
.menuListItem{
    padding-bottom:0.32rem;
    margin-left: 11px;
}
.menuListItem:hover,.menuListItem:active{
    opacity: 0.85;
}
.listItemIcon>i{
    display: inline-block;
    font-size:0.18rem;
    margin-left:0.11rem;
}

.listItemIcon>span{
    display: inline-block;
    margin-left:4px;
}

.right-menu>i{
    transform: rotate(180deg);
    display: inline-block;
    margin-right: 0.21rem;
    color: #C2C2C2;
}
.right-menu-switch{
    width: 0.51rem;height:0.31rem;border-radius:0.31rem;
    margin-right: 0.21rem;
    position: relative;
}
.right-menu-slider{
    height:0.27rem;
    width:0.27rem;
    border-radius:0.27rem;
    background-color: #FFFFFF;
    position: absolute;
    right:0.01rem;
    top:0.02rem;
}
.mine-icon {
    width:17px;
    height:17px;
}
.centerItem>a {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hidden-notice-dialog {
    /* position: absolute; */
    width: 283px;
    height: 167px;
    left: 0px;
    top: 0px;

    background: #FFFFFF;
    border-radius: 12px;
}

.hidden-title {
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    display: flex;
    padding-top: 0.1rem;
}
.hidden-desc {
    font-size: 13px;
    padding: 10px 30px;
}
.hidden-confirm-btn {
    width: 94px;
    height: 33px;
    left: 0px;
    top: 0px;

    /* Linear */
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 45px;
    font-size: 13px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
