.login-page{
    width: 100%;
    background: url('../../assets/images/login_back.png');
    background-size: cover;
}
.login-header{
    /* background: linear-gradient(180deg, #FFA9FA 0%, rgba(255, 147, 249, 0) 100%); */
    
    height:1.86rem;width: 100%;
}
.login-container{
    width: 100%;
    margin-top:-1.8rem;
}
.login-nav{
    margin-left: 0.26rem;
    margin-right:0.26rem;
}
.login-nav i{
    font-size:0.22rem;
}
.login-title{
    margin-left:0.2rem;
    margin-right:0.2rem;
    margin-top:0.53rem;
}
.login-title>div:last-child{
    margin-top:4px;
}
.login-input{
    width: 3.35rem;
    height: 56px;
    overflow: hidden;
    margin-bottom:0.21rem;
    border-bottom: #E4e5e9 solid 1px;
}

.login-submit{
    width: 3.35rem;
    height: 56px;
    overflow: hidden;
    margin-bottom:0.21rem;
    border-radius: 56px;
}


.login-input-active:hover{
      opacity:0.65;
}
.login-input-bg1{
    background: #F9FAFC;
}
.login-input-bg2{
    background: #E0E1E9;
}
.login-input-bg3{
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
}
.login-form{
    width:3.35rem;
    margin:0.49rem auto;
}
.login-label{
    margin-left:0.16rem;
}
.login-label>span,.login-label>i{
    font-size:0.15rem;
    color: #acacac;
}
.login-label>i{
    transform: rotate(-90deg);
    display: inline-block;
    margin-left:4px;
}
.phone-number{
     height:40px;
     width:2rem;
     outline: none;
     border: none;
     
     flex: 1;
     margin-left:0.12rem;
     margin-right:0.16rem;
     background-color: transparent;
     color: #212121;
}
.login-field{
    height:40px;
    width:2rem;
    outline: none;
    border: none;
    
    flex: 1;
    margin-left:0.12rem;
    margin-right:0.16rem;
    background-color: transparent;
    color: #212121;
}
.login-input>input:nth-child(1){
    width:2rem;
    height:40px;
    outline: none;
    border: none;
    margin-right:0.16rem;
    margin-left:0.16rem;
    background-color: transparent;
}
.z-index{
    z-index:-1;
}
.mb4{
    margin-bottom:0.4rem !important;
}
.login-send{
    right:0;
    z-index:333;
    width: 84px;
    height: 34px;
    border-radius:17px;
   
}
.login-send>div{
    line-height:34px;
}
.login-input:last-child>div{
    line-height:56px;
    text-align: center;
}
.login-submit:last-child>div{
    line-height:56px;
    text-align: center;
}