.fuapp-tababr{
    width:100%;
    position: fixed;
    left: 0;
    height:calc(60px + constant(safe-area-inset-bottom));
    height:calc(60px + env(safe-area-inset-bottom));
    /* height: 94px; */
    /* padding-bottom: 50px; */
    background: #FFFFFF;
    bottom:0px;
    z-index: 1999;
    overflow: hidden;
    border-top: 1px solid #ECECEC;
  }
  .tababrItem{
     height:100%;
     flex: 1;
     padding-top:0.12rem;
     position: relative;
  }
  .tababrItem>i{
    font-size:0.18rem;
  }

  .tababrItem>img{
    height: 19px;
    width: 18px;
  }


  .tababrItem>span{
      font-size:10px;
  }
  
  .tabbar-unread  {
    position: absolute;
    right: 0;
    left: 50%;
    top: 8px;
    background-color: red;
    /* width: 0.22rem; */
    border-radius: 8px;
    /* height: 0.22rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 15px;
    font-size: 10px;
  }