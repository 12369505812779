
.commentItem{
    /* width:3.29rem; */
    margin-bottom:0.28rem;
    padding: 0px 15px;
}

.black-remove{
    width: 0.50rem;
    height: 0.23rem;
    border-radius: 0.35rem;
    text-align: center;
    line-height: 0.23rem;
}
.comment-userid{
    margin-left: 0.1rem;
    font-weight: 500;
}
.comment-userid>div:first-child{
    line-height: 0.21rem;   
}
.comment-userid>div:last-child{
    color: #c6c6c6;
    line-height: 0.17rem;
}
.comment-remove:active{
    opacity: 0.65;
}
.all-comment-page {
    padding-top: 30px;
    height:95%;
    width: 100%;
}
.comment-content{
    margin-left: 0.1rem;
    margin-top: 0.05rem;
    font-weight: 400;
}
.comment-input{
    width: 269px;
    height: 30px;
    left: 0px;
    top: 0px;
    background: #F9FAFC;
    border-radius: 78px;
    /* border: gray solid 1px; */
    outline: none;
    border: none;
    padding-left: 8px;
}
.comment-submit {
        /* position: absolute; */
        width: 55px;
        height: 29px;
        left: 300px;
        top: 24px;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
        border-radius: 32px;
        border: none;
        color: white;
}