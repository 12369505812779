.msg-dialog-mask{
    padding-top: 22px;
    height: 1.2rem;
    width:1.5rem;
    overflow:scroll;
    position: absolute;
    bottom: 3.1rem;
    left: 1.2rem;
    -webkit-mask-image:linear-gradient(#00000066,#fff);
    /* z-index:-2; */
}
.v-msg-item{
    box-sizing: border-box;
}
.v-msg-item-cxt{
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px 6px;
    background:rgba(0,0,0,0.4);
    margin-bottom:10px;
    transition:  all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.z-index2{
    /* z-index: -10; */
}
.v-msg-input{
    left:0;
    bottom:0;
    padding-bottom:0.16rem;
    padding-top:0.08rem;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    border-top-left-radius:0.12rem;
    border-top-right-radius:0.12rem;
    background-color: #212121;
    height:0.56rem;
    overflow: hidden;
}

.v-msg-input>div>input{
    display: inline-block;
    height:0.36rem;
    border-radius:0.28rem;
    background-color: #fafafa;
    width:2.5rem;
    padding-left:0.14rem;
    padding-right: 0.14rem;
    outline: none;
    border: none;
    margin-left:0.12rem;
}
.v-msg-input>div>input::placeholder{
    color: #999999;
}
.v-msg-input>div>input::placeholder{
    color: #999999;
}
.v-msg-input>div>input::-ms-input-placeholder{
   color: #999999;
}
.v-msg-input>div>input::-moz-placeholder{
   color: #999999;
}

.send-msg-icon{
    width:0.28rem;height:0.28rem;
    display: inline-block;
    margin-right:0.12rem;
}

@keyframes bounceup{
    25% {-webkit-transform: translateX(0.12rem);}
    50%, 100% {-webkit-transform: translateX(0);}
    75% {-webkit-transform: translateX(-0.12rem);}
}
.animate-up{
    -webkit-animation:bounceup 0.6s linear both;
    animation:bounceup 0.6s linear both;
}

.v-msg-input{
    left:0;
        bottom:0;
        padding-bottom:0.16rem;
        border-top-left-radius:0.12rem;
        border-top-right-radius:0.12rem;
        background-color: #212121;
        height:0.56rem;
        overflow: hidden;
}
@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {    
    .v-msg-input{        
        padding-bottom:constant(safe-area-inset-bottom);        
        padding-bottom: env(safe-area-inset-bottom);        
        padding-bottom:0.66rem;    
} }

.msg-dialog-close {
    background-color: transparent;
    position: absolute;
    bottom: 4.3rem;
    left: 2.7rem;
    height: 47px;
}

.msg-dialog-close img {
    width: 22px;
    height: 22px;
    
}