




.liveList{
    margin-top:0.1rem;
    width:3.55rem;
}
.liveListItem{
    width:100%;border-radius:4px;height:3.55rem;
    margin-bottom: 0.1rem;
    overflow: hidden;
    position: relative;
}
.liveListItem>img{
    position: absolute;
    width:100%;
    height:100%;
    display: inline-block;
    object-fit: cover;
    left:0;
    top:0;
    z-index: -1;
}
.live-item-bottom {
    position: absolute;
    height: 0.7rem;
    left:0;
    right:0;
    bottom:0;
    background: linear-gradient(180deg, #ead47c00 1%, #1d1b1385 99%);
}

.on-line{
    width: 0.51rem;
    height: 0.27rem;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0.135rem;
    position: absolute;
    z-index: 22;
    top:0.15rem;
    /* left:0.15rem; */
    right:0.15rem;
}
.on-line-rd{
    width: 0.07rem;
    height: 0.07rem;
    border-radius:7px;
    background: #6BE5BD;
    border-radius: 0.07rem;
}
.off-line-rd{
    width: 0.07rem;
    height: 0.07rem;
    border-radius:7px;
    background: #D4d4d4;
    border-radius: 0.07rem;
}
.chatting-rd{
    width: 0.07rem;
    height: 0.07rem;
    border-radius:7px;
    background: #FF644B;
    border-radius: 0.07rem;
}
.active-rd{
    width: 0.07rem;
    height: 0.07rem;
    border-radius:7px;
    background: #FFBF45;
    border-radius: 0.07rem;
}
.dnd-rd{
    width: 0.07rem;
    height: 0.07rem;
    border-radius:7px;
    background: #555;
    border-radius: 0.07rem;
}
.on-line>span{
    display: inline-block;
}
.on-line>.on-line-rd{margin-right:0.06rem;}
.on-line>.off-line-rd{margin-right:0.06rem;}
.on-line>.chatting-rd{margin-right:0.06rem;}
.on-line>.active-rd{margin-right:0.06rem;}
.on-line>.dnd-rd{margin-right:0.06rem;}
.on-line>.font-size10{margin-right:0.09rem;}

.user-info{position: absolute;  z-index: 22;left:0.15rem;bottom: 0.15rem;}
.chat-price{position: absolute;  z-index: 22;right:0.15rem;bottom: 0.15rem;}
.chat-price>span{display: inline-block;}
.chat-price>.font-size13{margin-left:4px;}

.live-sign {
    max-width: 160px;
    max-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.live-status-text {
    white-space: nowrap;
}